import React, { useEffect, useMemo } from 'react'
import { useLocalization } from 'gatsby-theme-i18n'
import { navigate, withPrefix } from 'gatsby'
import DefaultLayout from '../layouts/DefaultLayout'

const MapsPage = () => {
  const { locale } = useLocalization()
  const langPrefix = useMemo(() => {
    return locale === 'en' ? '' : 'ar'
  })

  useEffect(() => {
    navigate(withPrefix(`${langPrefix}/maps/violations`))
  }, [langPrefix])

  return <DefaultLayout />
}

export default MapsPage
